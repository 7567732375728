//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty, isNull } from 'lodash';

export default {
  name: 'Section',
  props: {
    title: { default: '', type: String },
    noDataText: { default: '', type: String },
    isEmpty: { default: null, type: Boolean },
    data: { default: () => {}, type: [Array, Object] },
    morePath: { default: '', type: String },
    moreBottom: { default: null, type: Boolean },
  },
  data() {
    return {};
  },
  computed: {
    noData() {
      if (!isNull(this.isEmpty)) return this.isEmpty;
      return isEmpty(this.data);
    },
  },
  methods: {
    gotoMore() {
      this.$router.push(this.morePath);
    },
  },
};
