//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataBoardCountUp from './DataBoardCountUp';

export default {
  name: 'DataBoard',
  components: {
    DataBoardCountUp,
  },
  data() {
    return {
      list: [
        { title: '菁英資源', value: 80000, unit: '+' },
        { title: '企業面試率', value: 76, unit: '%' },
        { title: '企業任用率', value: 82, unit: '%' },
        { title: '企業續約率', value: 92, unit: '%' },
      ],
    };
  },
};
