//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeCarousel',
  props: {
    list: { type: [Array, Object], default: () => {} },
    type: { type: String, default: 'company' },
  },
};
