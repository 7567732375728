//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'CompanyList',
  props: { type: { type: Number, default: 0 } },
  data() {
    return {
      companyList: [],
    };
  },
  computed: {
    title() {
      switch (this.type) {
        case 0:
          return '精選企業・嚴選推薦';

        case 1:
        default:
          return '熱門企業・擴編招聘';
      }
    },
    morePath() {
      switch (this.type) {
        case 0:
          return '/Company';

        case 1:
        default:
          return '/Enterprise';
      }
    },
  },
  methods: {
    ...mapActions('jobs', ['setCurrentCompanyId']),
    gotoCompany(id) {
      this.setCurrentCompanyId(id || 0);
      this.$router.push(`/Company/${id}`);
    },
    getCompany() {
      this.$api('getCompanyPopular', { confirmType: this.type }).then(data => {
        if (data.length) return (this.companyList = data);
      });
    },
  },
  mounted() {
    this.getCompany();
  },
};
