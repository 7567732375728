//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { articleMenu } from '~/utils/article';

export default {
  name: 'ArticleCard',
  props: {
    item: { type: Object, default: () => {} },
  },
  data() {
    return {
      articleMenu,
    };
  },
  computed: {
    ...mapGetters('article', ['category', 'currentId']),
    color() {
      return this.articleMenu[this.item.categoryId].color;
    },
  },
  methods: {
    ...mapActions('article', ['setCategory', 'setCurrentId']),
    gotoList(value, id) {
      if (this.category === value && this.currentId === id) return;
      this.setCategory(value);
      this.setCurrentId(id || 0);
      this.$router.push(`/ArticleContent/${id}`);
    },
  },
  filters: {
    previewContent(content) {
      if (content === null || content === undefined) return '';

      if (content.length > 70) {
        return content + '...';
      }

      return content;
    },
  },
  mounted() {
    this.isFavorite = !!this.item.isFavorites;
  },
};
