// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/ui/home-data-bg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body[data-v-21aa91e2]{margin:0;overflow-x:hidden;font-family:\"Noto Sans TC\",sans-serif,\"微軟正黑體\",arial}#__layout[data-v-21aa91e2],#__nuxt[data-v-21aa91e2],.container[data-v-21aa91e2],body[data-v-21aa91e2]{height:100%}.container[data-v-21aa91e2]{box-sizing:border-box;width:100%;max-width:1240px;margin:auto;padding:0 20px}@media (max-width:540px){.container[data-v-21aa91e2]{padding:0 10px}}p[data-v-21aa91e2]{margin:.25em 0;line-height:1.25em}h1[data-v-21aa91e2]{font-size:48px}h2[data-v-21aa91e2]{font-size:26px}@media (max-width:768px){h1[data-v-21aa91e2]{font-size:32px}h2[data-v-21aa91e2]{font-size:16px}}.grecaptcha-badge[data-v-21aa91e2]{visibility:hidden}.hideHeading[data-v-21aa91e2]{position:absolute;width:100%;opacity:0}.dataBoardWrapper[data-v-21aa91e2]{width:100%;height:auto;position:relative;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:cover}.dataBoardWrapper .textArea[data-v-21aa91e2]{width:100%;height:auto;position:relative;display:flex;justify-content:space-between;align-items:flex-start;flex-direction:row;flex-wrap:wrap;margin:0 auto;padding:48px 0}@media (max-width:768px){.dataBoardWrapper .textArea[data-v-21aa91e2]{padding:20px 0}}@media (max-width:768px){.dataBoardWrapper .textArea .textItem[data-v-21aa91e2]{width:50%;padding:20px 0;text-align:center}}@media (max-width:360px){.dataBoardWrapper .textArea .textItem[data-v-21aa91e2]{width:100%}}.dataBoardWrapper .textArea .textItem .title[data-v-21aa91e2]{font-size:20px;color:#fff;font-size:calc(1vw + 8px)}.dataBoardWrapper .textArea .textItem .value[data-v-21aa91e2]{font-size:72px;color:#fff;font-weight:700;font-size:calc(2vw + 36px)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
