//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeSearchbar',
  data() {
    return {
      suggestKeywords: [],
      searchPayload: {
        keywords: '',
      },
      searchbarHeight: 212,
      isDown: false,
    };
  },
  methods: {
    gotoJobs() {
      this.$router.push({
        name: 'Jobs',
        query: { keywords: JSON.stringify(this.searchPayload.keywords) },
      });
    },
    getSuggestKeywords() {
      this.$api('getKeywords').then(data => {
        this.suggestKeywords = data.map(item => item.value);
      });
    },
    addKeyword(word) {
      this.searchPayload.keywords = word;
    },
  },
  mounted() {
    this.getSuggestKeywords();
  },
};
