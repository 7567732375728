// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/ui/contact-us-bg.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/ui/contact-us-bg-mob.webp");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body[data-v-a6bdf7f0]{margin:0;overflow-x:hidden;font-family:\"Noto Sans TC\",sans-serif,\"微軟正黑體\",arial}#__layout[data-v-a6bdf7f0],#__nuxt[data-v-a6bdf7f0],.container[data-v-a6bdf7f0],body[data-v-a6bdf7f0]{height:100%}.container[data-v-a6bdf7f0]{box-sizing:border-box;width:100%;max-width:1240px;margin:auto;padding:0 20px}@media (max-width:540px){.container[data-v-a6bdf7f0]{padding:0 10px}}p[data-v-a6bdf7f0]{margin:.25em 0;line-height:1.25em}h1[data-v-a6bdf7f0]{font-size:48px}h2[data-v-a6bdf7f0]{font-size:26px}@media (max-width:768px){h1[data-v-a6bdf7f0]{font-size:32px}h2[data-v-a6bdf7f0]{font-size:16px}}.grecaptcha-badge[data-v-a6bdf7f0]{visibility:hidden}.hideHeading[data-v-a6bdf7f0]{position:absolute;width:100%;opacity:0}.contactUsWrapper[data-v-a6bdf7f0]{width:100%;height:auto;position:relative;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:cover;height:calc(100vh - 72px);min-height:600px;padding-top:48px}@media (max-width:768px){.contactUsWrapper[data-v-a6bdf7f0]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-position:bottom;background-size:200%;height:calc(100vh - 60px);min-height:400px}}.contactUsWrapper .title[data-v-a6bdf7f0]{font-size:72px;color:#000;font-weight:700;font-size:calc(2vw + 46px);text-align:center;opacity:0;transform:translateY(100%);transition:transform 1s,opacity 1s}.contactUsWrapper .title.isShow[data-v-a6bdf7f0]{opacity:1;transform:translateY(0)}.contactUsWrapper .button[data-v-a6bdf7f0]{font-size:20px;color:#fff;width:282px;height:72px;display:flex;justify-content:center;align-items:center;flex-direction:row;flex-wrap:nowrap;margin:48px auto 0;background-color:#59bbe5;border-radius:5px;text-decoration:none;text-decoration:initial}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
