//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainVisual from './_components/MainVisual';
import NewVisual from './_components/NewVisual';
import DataBoard from './_components/DataBoard';
import JobSearch from './_components/JobSearch';
import CompanyList from './_components/CompanyList';
import CityList from './_components/CityList';
import Article from './_components/Article';
import ServicesItem from './_components/ServicesItem';
import ServicesRage from './_components/ServicesRage';
import ContactUs from './_components/ContactUs';

import { mapActions } from 'vuex';
import { jsonLdJob } from '@/utils/jobs';
import { formatDateTime } from '@/utils/filters/filterTime';

const axios = require('axios');

const download = (key, value) => {
  return new Promise((resolve, reject) => {
    try {
      const blob = new Blob([JSON.stringify(value)], {
        type: 'text/plain',
      });
      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a');
      a.download = `${key}.json`;

      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null,
      );
      a.dispatchEvent(e);
      a.remove();
      resolve();
    } catch (err) {
      console.log('err:', err);
      reject(err);
    }
  });
};

const wait = sec => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, sec * 1000);
  });
};

export default {
  name: 'Home',
  components: {
    MainVisual,
    NewVisual,
    DataBoard,
    JobSearch,
    CompanyList,
    CityList,
    Article,
    ServicesItem,
    ServicesRage,
    ContactUs,
  },
  data() {
    return { jobJsonld: {}, jsonldSize: 0 };
  },
  watch: {
    async jobJsonld(data) {
      if (Object.keys(data).length === this.jsonldSize) {
        this.setJsonldObj(data);

        let arr = [];
        for (const [key, value] of Object.entries(data)) {
          arr.push({ key, value });
        }
        await arr.reduce(async (previousPromise, item, index) => {
          await previousPromise;
          const { key, value } = item;
          if (index % 5 === 0) {
            await wait(0.5);
          }
          return download(key, value);
        }, Promise.resolve());
      }
    },
  },
  mounted() {
    // this.getPRODJobJsonld();
  },
  methods: {
    ...mapActions('jsonld', ['setJsonldObj']),
    async getPRODJobJsonld() {
      const apiURL =
        process.env.deployEnv === 'PROD'
          ? 'https://step1ne.com/apis/job-position/search'
          : 'https://dev.step1ne.com/apis/job-position/search';
      const data = await axios.post(apiURL, {
        current: 1,
        size: 1000,
        total: 0,
      });

      const { records } = data.data.data;
      this.jsonldSize = records.length;

      if (records) {
        const apiDetailURL =
          process.env.deployEnv === 'PROD'
            ? 'https://step1ne.com/apis/job-position'
            : 'https://dev.step1ne.com/apis/job-position';
        records.map(item => {
          axios
            .get(`${apiDetailURL}/${item.id}`, {
              current: 1,
              size: 1000,
              total: 0,
            })
            .then(detailData => {
              const detail = {
                '@context': 'https://schema.org/',
                '@type': 'JobPosting',
                title: detailData.title,
                description: detailData.content,
                identifier: {
                  '@type': 'PropertyValue',
                  name: 'step1ne',
                  value: detailData.id,
                },
                datePosted: `${formatDateTime(detailData.createTime)} +0800`,
                validThrough: '2023-12-31T00:00',
                employmentType: jsonLdJob[detailData.type],
                hiringOrganization: {
                  '@type': 'Organization',
                  name: '德仁管理顧問有限公司',
                  sameAs: `https://step1ne.com/JobsContent/${detailData.id}`,
                  logo: detailData.ownerLogoUrl,
                },
                jobLocation: {
                  '@type': 'Place',
                  address: {
                    '@type': 'PostalAddress',
                    // "streetAddress": this.asyncJobDetail.ownerAddress,
                    streetAddress: '瑞光路335號4樓23室',
                    addressLocality: '台北市',
                    addressRegion: '內湖區',
                    postalCode: '11492',
                    addressCountry: 'TW',
                  },
                },
                baseSalary: {
                  '@type': 'MonetaryAmount',
                  currency: 'NTD',
                  value: {
                    '@type': 'QuantitativeValue',
                    // minValue: 40000,
                    unitText: 'MONTH',
                  },
                },
              };

              this.jobJsonld = { ...this.jobJsonld, [item.id]: detail };
            });
        });
      }
    },
  },
};
