//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MainVisual',
  props: { bannersData: { type: Array, default: () => [] } },
  data() {
    return {
      showTime: 5000,
      activeIndex: 0,
      banners: this.bannersData,
      play: null,
      isError: false,
    };
  },
  methods: {
    getBanners() {
      this.$api('getBanners').then(data => {
        this.banners = data;
      });
    },
    bannersPlay() {
      this.play = setInterval(() => {
        this.activeIndex++;
        if (this.activeIndex >= this.banners.length) this.activeIndex = 0;
      }, this.showTime);
    },
    bannerImgError() {
      this.isError = true;
    },
  },
  mounted() {
    this.bannersPlay();
    this.getBanners();
  },
  beforeDestroy() {
    clearInterval(this.play);
  },
};
