//
//
//
//
//
//
//
//
//
//
//
//

import Observer from 'vue-intersection-observer';

export default {
  name: 'ContactUs',
  components: {
    Observer,
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    gotoContact() {
      this.$router.push('ContactUs');
    },
    onChange(entry, unobserve) {
      this.isShow = !!entry.isIntersecting;
    },
  },
};
