//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Observer from 'vue-intersection-observer';

export default {
  name: 'NewVisual',
  components: {
    Observer,
  },
  data() {
    return {
      show: false,
      subTitle: '不只解決人才需求，更要打造長期人才戰略',
    };
  },
  methods: {
    delay1(value) {
      return { animationDelay: value * 500 + 'ms' };
    },
    delay2(value) {
      return { animationDelay: 2500 + value * 50 + 'ms' };
    },
    onChange(entry, unobserve) {
      this.show = entry.isIntersecting;
    },
  },
};
