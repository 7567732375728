export const jobTypes = [
  { label: '全職', value: 0 },
  { label: '派遣', value: 1 },
  { label: '實習', value: 2 },
  { label: '兼職', value: 3 },
  { label: '遠端', value: 4 },
  { label: '短期工讀', value: 5 },
];

export const jsonLdJob = [
  '全職',
  '約聘/派遣',
  '實習,',
  '兼職',
  '遠端工作',
  '短期工讀 ',
];

export const jobMenu = [
  { label: '熱門', value: null },
  jobTypes[0],
  jobTypes[3],
  jobTypes[4],
  jobTypes[1],
];

export const salaryType = [
  { label: '面議', value: 0 },
  { label: '時薪', value: 1 },
  { label: '月薪', value: 2 },
  { label: '年薪', value: 3 },
];

export const holidayType = [
  { label: '週休二日', value: 1 },
  { label: '排班休', value: 2 },
  { label: '依公司規定', value: 3 },
];

export const workTimeType = [
  { label: '日班', value: 1 },
  { label: '晚班', value: 2 },
  { label: '大夜班', value: 3 },
  { label: '假日班', value: 4 },
];

export const specificApplicantType = [
  { label: '不拘', value: 0 },
  { label: '上班族', value: 1 },
  { label: '學生', value: 2 },
  { label: '應屆畢業生', value: 3 },
  { label: '原住民', value: 4 },
  { label: '外籍人士', value: 5 },
  { label: '二度就業', value: 6 },
  { label: '身心障礙', value: 7 },
  { label: '研發替代役', value: 8 },
];

export const educationLevel = [
  { label: '不拘', value: 0 },
  { label: '高中以下', value: 1 },
  { label: '高中以上', value: 2 },
  { label: '專科以上', value: 3 },
  { label: '大學以上', value: 4 },
  { label: '碩士以上', value: 5 },
  { label: '博士以上', value: 6 },
];

export const highestEducation = [
  { label: '國小', value: 0 },
  { label: '國中', value: 1 },
  { label: '高中', value: 2 },
  { label: '五專', value: 3 },
  { label: '學士', value: 4 },
  { label: '碩士', value: 5 },
  { label: '博士', value: 6 },
];

export const languageLevels = [
  { value: 0, label: '不拘' },
  { value: 1, label: '略懂' },
  { value: 2, label: '普通' },
  { value: 3, label: '精通' },
];
