//
//
//
//
//
//
//
//

export default {
  name: 'CityList',
  data() {
    return {
      cityList: [
        { label: '台北市', value: '6001001000' },
        { label: '新北市', value: '6001002000' },
        // { label: '桃園市', value: '6001005000' },
        // { label: '高雄市', value: '6001006000' },
        { label: '台中市', value: '6001008000' },
        { label: '台南市', value: '6001014000' },
      ],
    };
  },
  methods: {
    getCityPopular() {
      this.$api('getAreaPopular').then(data => {
        if (data.length) return (this.cityList = data);
      });
    },
  },
  mounted() {
    // 尚未提供圖片 先寫死
    // this.getCityPopular();
  },
};
