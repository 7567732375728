//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ServicesRage',
  data() {
    return {
      servicesRageList: [
        { label: '中高階獵才', img: '1' },
        { label: '人資外包', img: '2' },
        { label: '薪資代算', img: '3' },
        { label: '教育訓練', img: '4' },
      ],
    };
  },
};
