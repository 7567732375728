import { render, staticRenderFns } from "./HomeCarousel.vue?vue&type=template&id=0f884df0&scoped=true&"
import script from "./HomeCarousel.vue?vue&type=script&lang=js&"
export * from "./HomeCarousel.vue?vue&type=script&lang=js&"
import style0 from "./HomeCarousel.vue?vue&type=style&index=0&id=0f884df0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f884df0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardCityCard: require('/app/src/components/Card/CityCard.vue').default,CardCompanyCard: require('/app/src/components/Card/CompanyCard.vue').default,Carousel: require('/app/src/components/Carousel/index.vue').default})
