//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'Article',
  data() {
    return {
      articleList: [],
    };
  },
  methods: {
    ...mapActions('article', ['setCategory', 'setCurrentId']),
    async getArticle() {
      this.$api('getRandomArticle', { number: 3 }).then(data => {
        this.articleList = data;
      });
    },
  },
  mounted() {
    this.getArticle();
  },
};
