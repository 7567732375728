//
//
//
//
//
//

import Searchbar from '~/components/Searchbar/HomeSearchbar';

export default {
  name: 'JobSearch',
  components: { Searchbar },
  methods: {
    gotoJobs(value) {
      this.$router.push({
        name: 'Jobs',
        query: { areaIds: JSON.stringify([value]) },
      });
    },
  },
};
