//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'CompanyCard',
  props: {
    item: { type: Object, default: () => {} },
    home: { type: Boolean, default: false },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  methods: {
    ...mapActions('jobs', ['setCurrentCompanyId']),
    gotoCompany(id) {
      this.setCurrentCompanyId(id || 0);
      this.$router.push(`/Company/${id}`);
    },
  },
};
