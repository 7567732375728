//
//
//
//
//
//
//
//
//

export default {
  name: 'CityCard',
  props: {
    item: { type: Object, default: () => {} },
  },
  methods: {
    gotoJobs(value) {
      this.$router.push({
        name: 'Jobs',
        query: { areaIds: JSON.stringify([value]) },
      });
    },
  },
};
