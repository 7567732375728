//
//
//
//
//
//
//
//
//

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'Carousel',
  props: {
    games: {
      type: [Array, Object],
      default: () => {},
    },
    dots: {
      type: Boolean,
      default: false,
    },
  },
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: true,
        dots: this.dots,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              arrows: true,
              dots: this.dots,
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 3,
              rows: 1,
            },
          },
          {
            breakpoint: 960,
            settings: {
              arrows: true,
              dots: this.dots,
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              dots: this.dots,
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      },
    };
  },
};
