//
//
//
//
//
//

import ICountUp from 'vue-countup-v2';
import Observer from 'vue-intersection-observer';

export default {
  name: 'DataBoardCountUp',
  components: {
    ICountUp,
    Observer,
  },
  props: {
    value: { type: Number, default: 0 },
    delay: { type: Number, default: 0 },
    unit: { type: String, default: '' },
  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: '',
      },
    };
  },
  methods: {
    onChange(entry, unobserve) {
      const that = this;
      let startCount;

      if (entry.isIntersecting) {
        startCount = setTimeout(() => {
          this.$refs.count.instance.update(that.value);
        }, this.delay);
      } else {
        clearTimeout(startCount);
        this.$refs.count.instance.reset();
      }
    },
  },
};
